import https from "./https";
import store from "@/store";

// TODO: 需確認 API 後在完成此部分
const worker = {
  getInfo(payload) {
    const organization = store.state.liffGeneral.orgCode;
    const params = new URLSearchParams(payload).toString()
    return https.get(`${organization}/liff/partner-worker/info?${params}`);
  },

  checkRegistered() {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/partner-worker/check-registered`);
  },

  allowNewWorkerRegistered(payload) {
    const organization = store.state.liffGeneral.orgCode;
    const params = new URLSearchParams(payload).toString()
    return https.get(`${organization}/liff/partner-worker/allow-new-registered?${params}`);
  },

  getPartners(payload) {
    const organization = store.state.liffGeneral.orgCode;
    const params = new URLSearchParams(payload).toString()
    return https.get(`${organization}/liff/partner-worker/partners?${params}`);
  },

  active(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/partner-worker/active`, payload);
  },

  verifyPhone(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/partner-worker/phone/verify`, payload);
  },

  sendPhone(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/partner-worker/phone/send`, payload);
  },

  resendPhone(payload) {
    const organization = store.state.liffGeneral.orgCode;
    return https.post(`${organization}/liff/partner-worker/phone/resend`, payload);
  },
};

export default worker;
