const MODULE_CODE = "worker_active";
import workerApi from "../../apis/liff/v2/worker";

export default {
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    async checkRegistered() {
      let response = await workerApi.checkRegistered();
      let next = response.data.next ?? "unknown";

      switch (next) {
        case "active_page":
          if (
            ![
              "LiffWorkerTerms",
              "LiffWorkerActive",
              "LiffWorkerValidate",
              "LiffWorkerConfirm",
            ].includes(this.$route.name)
          ) {
            this.$router.push({
              name: "LiffWorkerIndex",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "thanks_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (!["LiffWorkerThanks"].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffWorkerThanks",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "pending_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (!["LiffWorkerPending"].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffWorkerPending",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "welcome_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (!["LiffWorkerWelcome"].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffWorkerWelcome",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "failed_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (!["LiffWorkerFailed"].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffWorkerFailed",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
        case "worker_staff_page":
          // 如果當前頁面不在這個範圍內，則前往
          if (!["LiffWorkerStaffInfo"].includes(this.$route.name)) {
            this.$router.push({
              name: "LiffWorkerStaffInfo",
              params: {
                orgCode: this.$route.params.orgCode,
              },
            });
            return false;
          }
          break;
      }

      return true;
    },
    goSuccess(next) {
      switch (next) {
        case "thanks_page":
          this.$router.push({ name: "LiffWorkerThanks" });
          break;
        case "pending_page":
          this.$router.push({ name: "LiffWorkerPending" });
          break;
        case "failed_page":
          this.$router.push({ name: "LiffWorkerFailed" });
          break;
        case "worker_staff_page":
          this.$router.push({ name: "LiffWorkerStaffInfo" });
          break;
        case "welcome_page":
        default:
          this.$router.push({
            name: "LiffWorkerWelcome",
            params: {
              orgCode: this.$route.params.orgCode,
            },
          });
          break;
      }
    },
  },
};
