<template>
  <div>
    <Loading v-if="displayLoading"></Loading>
    <div v-else class="page">
      <div class="page__title">
        {{ getModuleConfig("active_page.form_title") }}
      </div>
      <div class="page__desc">
        <div class="text-center mt-3 mb-5">
          <h5>{{ getModuleConfig("active_page.form_desc") }}</h5>
        </div>
      </div>
      <form class="form">
        <!-- fields loop start -->
        <template v-for="(field, index) in verifyFields">
          <!-- text type -->
          <div :key="index" v-if="field.type === 'text' && field.matched_key" class="field">
            <label class="field__label" :class="field.required === true ? 'field--required' : null">{{ field.title
              }}</label>
            <input class="field__input" v-model="form[field.matched_key]"
              :class="{ invalid: v$.form[field.matched_key].$error }" :placeholder="displayPlaceholder(field)"
              :required="field.required" />
            <small v-if="field.memo" class="field__desc">{{
      field.memo
    }}</small>
            <div v-if="v$.form[field.matched_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- text type -->
        </template>
        <!-- fields loop end -->
      </form>
    </div>
    <div
      class="reminder"
      v-html="getModuleConfig('active_page.reminder')"
    ></div>
    <div class="page__button">
      <SharedButton class="s-btn-bg-primary" type="button" @click="handleSubmit">
        {{ getModuleConfig('active_page.button_text') }}
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
// import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import LiffChecker from "@/utils/LiffChecker";
import _ from "lodash";
import workerMixin from "@/mixins/liff/worker";
import workerApi from "@/apis/liff/v2/worker";
import VConsole from 'vconsole';

export default {
  mixins: [workerMixin],
  components: {
    Loading,
    SharedButton
  },

  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      displayLoading: false,
      verifyFields: [],
      lineData: {},
      form: {},
    };
  },

  validations() {
    let form = {};

    for (let i = 0; i < this.verifyFields.length; i++) {
      let field = this.verifyFields[i];
      // 特殊規則 手機
      if (field.matched_key === "mobile_phone") {
        form.mobile_phone = this.isFieldRequired("mobile_phone")
          ? {
            required,
            mobile: (val) => {
              return /09[0-9]{8}/.test(val);
            },
          }
          : {};

        continue;
      }

      // 一般欄位規則
      form[field.matched_key] = field.required === true ? { required } : {};
    }
    return {
      form,
    };
  },

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      if (this.$route.query['vconsole']) {
        new VConsole({ theme: 'dark' });
      }

      let result = await this.checkRegistered()

      if (result === false) {
        return;
      }
      this.verifyFields = _.sortBy(this.getModuleConfig('active_page.verify_field'), 'order')

      const checker = new LiffChecker(this.$route.params.orgCode);
      this.lineData = await checker.getLineData();
    },
    isFieldRequired(key) {
      return this.verifyFields.some(
        (field) => field.matched_key === key && field.required == true
      );
    },
    async checkNewRegistered() {
      try {
        await workerApi.allowNewWorkerRegistered(this.formatPayload())
        return true;
      } catch (error) {
        if (
          error.response.data.status === "failed" &&
          error.response.data.message
        ) {
          this.$swal('啟用失敗', error.response.data.message, 'error');
        }
      }
      return false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      const registered = await this.checkNewRegistered()
      if(!registered) {
        return
      }
      this.displayLoading = true;
      if (this.getModuleConfig('verify_mobile.enable')) {
        this.$router.push({
          name: "LiffWorkerValidate",
          query: this.formatPayload()
        });
      } else {
        this.$router.push({
          name: "LiffWorkerConfirm",
          query: this.$route.query,
        });
      }
      this.displayLoading = false;
    },
    formatPayload() {
      let payload = this.form
      payload.line_id = this.lineData.profile.userId
      payload.line_access_token = this.lineData.accessToken
      return payload
    },
    displayPlaceholder(field, defaultMessage = null) {
      if (field.placeholder) return field.placeholder;
      if (defaultMessage) return defaultMessage;
      if (field.title) return "請輸入" + field.title;
      return "";
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/shared/components/_fields.scss';

.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
}

.form {
  padding: 20px 10px;

  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.reminder {
  font-size: 12px;
  color: var(--liff-secondary_text_color);
  margin: 12px;
  a {
    text-decoration: underline;
  }
}
</style>
